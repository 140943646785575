import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Coach, Projected } from '../../models/coach';
import { RankStatus, getSCStatus } from '../../../utils';
import { getEDStatus } from './../../../utils/index';

@Component({
  selector: 'app-pinned-coach-card',
  templateUrl: './pinned-coach-card.component.html',
  styleUrls: ['./pinned-coach-card.component.scss']
})
export class PinnedCoachCardComponent implements OnInit {
  @Input() coach: Coach;
  @Output() unpinned: EventEmitter<Coach> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getBarValue(gv: Projected) {
    return (gv.current / gv.projected) * 100;
  }

  willHitSeniorCoach(coach: Coach) {
    return coach.orderingEntities.projected >= 5 && coach.frontLineVolume.projected >= 1200;
  }

  hasHitSeniorCoach(coach: Coach) {
    return coach.orderingEntities.current >= 5 && coach.frontLineVolume.current >= 1200;
  }

  unpinCoach(coach: Coach) {
    this.unpinned.emit(coach);
  }

  getSCBadgeClass(coach: Coach) {
    const rankStatus = getSCStatus(coach);
    return this.getBadgeForRankStatus(rankStatus);
  }

  getEDBadgeClass(coach: Coach) {
    const rankStatus = getEDStatus(coach);
    return this.getBadgeForRankStatus(rankStatus);
  }

  showEDBadge(coach: Coach) {
    const rankStatus = getSCStatus(coach);
    return rankStatus === RankStatus.PROJECTED_TO_MEET || rankStatus === RankStatus.MET;
  }

  getTextColor(coach) {
    if (this.hasHitSeniorCoach(coach)) {
      return '#4dbd74';
    } else if (this.willHitSeniorCoach(coach)) {
      return '#ffc107';
    }

    return '#f86c6b';
  }

  getColorClass(coach) {
    if (this.hasHitSeniorCoach(coach)) {
      return 'bg-success';
    } else if (this.willHitSeniorCoach(coach)) {
      return 'bg-warning';
    }

    return 'bg-danger';
  }

  private getBadgeForRankStatus(rank: RankStatus) {
    if (rank === RankStatus.MET) {
      return 'badge-success';
    } else if (rank === RankStatus.PROJECTED_TO_MEET) {
      return 'badge-warning';
    }
    return 'badge-danger';
  }
}
