import { Coach } from "../dashboard/models/coach";

export const searchTree = (tree, comparer) => {
    // TODO This feels like it is wrong and is going to return the last node if it doesn't find anything
    const stack = [];
    let node, ii;
    stack.push(tree);

    while (stack.length > 0) {
        node = stack.pop();
        if (comparer(node)) {
            break;
        } else if (node.children && node.children.length) {
            for (ii = 0; ii < node.children.length; ii += 1) {
                stack.push(node.children[ii]);
            }
        }
    }
    return comparer(node) ? node : null;
};

export enum RankStatus {
    PROJECTED_TO_MEET, MET, WILL_NOT_MEET
}

export const getEDStatus = (coach: Coach): RankStatus => {
    if (coach.qualifyingPoints.current >= 5) {
        return RankStatus.MET;
    } else if (coach.qualifyingPoints.projected >= 5) {
        return RankStatus.PROJECTED_TO_MEET;
    }
    return RankStatus.WILL_NOT_MEET;
};

export const isSeniorCoachTeam = (org: Coach) => {
    return org.orderingEntities.current >= 5 && org.frontLineVolume.current > 1200;
};

export const isProjectedSeniorCoachTeam = (org: Coach) => {
    return org.orderingEntities.projected >= 5 && org.frontLineVolume.projected > 1200;
};


export const getSCStatus = (coach: Coach): RankStatus => {
    if (isSeniorCoachTeam(coach)) {
        return RankStatus.MET;
    } else if (isProjectedSeniorCoachTeam(coach)) {
        return RankStatus.PROJECTED_TO_MEET;
    }
    return RankStatus.WILL_NOT_MEET;

};