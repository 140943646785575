import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import {
  DashboardPageActionTypes,
  GetOrganizationData,
  GetOrganizationDataSuccess,
  GetOrganizationDataFailure,
  BuildOrgTree,
  BuildOrgTreeComplete
} from '../actions/dashboard-page.actions';
import { OrganizationService } from '../services/organization.service';

@Injectable()
export class DashboardPageEffects {
  @Effect()
  getOrgStatsWithProjected$ = this.actions$.pipe(
    ofType<GetOrganizationData>(DashboardPageActionTypes.GetOrganizationData),
    exhaustMap((action) =>
      this.organizationService.getOrganizationStatsWithProjected().pipe(
        map(orgData => new GetOrganizationDataSuccess({ coaches: orgData.combined, organization: orgData.organization })),
        catchError(error => of(new GetOrganizationDataFailure(error)))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private organizationService: OrganizationService,
    private router: Router
  ) {}
}
