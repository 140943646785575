import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DashboardPageComponent } from './containers/dashboard-page.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { AppSidebarModule, AppBreadcrumbModule } from '@coreui/angular';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { OrganizationService } from './services/organization.service';
import { DashboardPageEffects } from './effects/dashboard-page.effects';
import { reducers } from './reducers';
import { NavCoachListComponent } from './components/nav-coach-list/nav-coach-list.component';
import { NavCoachListItemComponent } from './components/nav-coach-list/nav-coach-list-item/nav-coach-list-item.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CardListComponent } from './components/card-list/card-list.component';
import { PinnedCoachCardComponent } from './components/pinned-coach-card/pinned-coach-card.component';
import { SharedModule } from '../shared/shared.module';
import { TabularPageComponent } from './containers/tabular-page/tabular-page.component';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { ModalModule } from 'ngx-bootstrap/modal';
export const COMPONENTS = [DashboardPageComponent, NavCoachListComponent,
    NavCoachListItemComponent, CardListComponent, PinnedCoachCardComponent, TabularPageComponent];

@NgModule({
    imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppSidebarModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        ChartsModule,
        DashboardRoutingModule,
        CommonModule,
        AppBreadcrumbModule.forRoot(),
        EffectsModule.forFeature([DashboardPageEffects]),
        StoreModule.forFeature('dashboard', reducers),
        FlexLayoutModule,
        AngularSlickgridModule.forRoot(),
        ModalModule.forRoot(),
        SharedModule
    ],
    providers: [OrganizationService],
    declarations: COMPONENTS,
    exports: COMPONENTS,
})
export class DashboardModule { }
