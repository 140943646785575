import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { OptAuthenticate } from '../models/user';
import * as fromAuth from '../reducers';
import * as AuthActions from '../actions/opt-auth.actions';

@Component({
  selector: 'slay-opt-login-page',
  templateUrl: './opt-login-page.component.html'
})
export class OptLoginPageComponent implements OnInit {
  pending$: any;
  error$: any;
  fbLoggedIn$: any;
  constructor(private store: Store<fromAuth.State>) {}

  ngOnInit() {
    this.store.dispatch(new AuthActions.GetUser());
    this.pending$ = this.store.pipe(select(fromAuth.getLoginPagePending));
    this.error$ = this.store.pipe(select(fromAuth.getLoginPageError));
    this.fbLoggedIn$ = this.store.pipe(select(fromAuth.getFbLoggedIn));
  }

  onSubmit($event: OptAuthenticate) {
    this.store.dispatch(new AuthActions.OptLogin($event));
  }

  signInWithFacebook() {
    this.store.dispatch(new AuthActions.Login());
  }
}
