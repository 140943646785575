import { Injectable } from '@angular/core';
import { Observable, from, of, throwError } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { OptAuthenticate, OptUser } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';


@Injectable()
export class OptAuthService {
  baseUrl = environment.urls.authBase;
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  constructor(private _firebaseAuth: AngularFireAuth, private router: Router, private http: HttpClient) {
    this.user = _firebaseAuth.authState;
  }

  loginOpt({ username, password }: OptAuthenticate): Observable<any> {
     return this.http.post(this.baseUrl + 'login', {username: username, password: password});
  }

  // TODO: something here
  logoutOpt() {
    return of(true);
  }

  signInWithFacebook() {
    return from(this._firebaseAuth.auth.signInWithPopup(
      new firebase.auth.FacebookAuthProvider()
    ));
  }

  isLoggedIn() {
    if (this.userDetails == null) {
      return false;
    } else {
      return true;
    }
  }

  logout() {
    this._firebaseAuth.auth.signOut()
      .then((res) => this.router.navigate(['/']));
  }

  getUser() {
    return this.user;
  }

  getUserDetails() {
    return this.userDetails;
  }
}
