import { Component, OnInit } from '@angular/core';
import { SystemMessagesService } from '../shared/services/system-messages.service';
import { SystemMessage } from './../shared/services/SystemMessage';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss']
})
export class SystemMessageComponent implements OnInit {

  constructor(private systemMessage: SystemMessagesService) { }

  messages: Observable<SystemMessage[]>;
  ngOnInit() {
    this.messages = this.systemMessage.getSystemMessages();
  }

}
