import {
  DashboardPageActionTypes,
  DashboardPageActionsUnion,
} from '../actions/dashboard-page.actions';
import { Coach } from '../models/coach';
import { GetOrganizationDataSuccess } from './../actions/dashboard-page.actions';
import { GridState } from 'angular-slickgrid';

export interface State {
  showSidenav: boolean;
  coaches: any[];
  pinnedCoachesIds: any[];
  loading: boolean;
  error: any;
  orgTree: any;
  coachFilterValue: string;
  savedQuery: { [key: string]: GridState},
  savedQueryFilterValue: string
}

const initialState: State = {
  showSidenav: false,
  coaches: [],
  pinnedCoachesIds: [],
  loading: false,
  error: null,
  orgTree: null,
  coachFilterValue: '',
  savedQuery: {},
  savedQueryFilterValue: ''
};

export function reducer(
  state: State = initialState,
  action: DashboardPageActionsUnion
): State {
  switch (action.type) {
    case DashboardPageActionTypes.GetOrganizationData: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case DashboardPageActionTypes.GetOrganizationDataSuccess: {
      return {
        ...state,
        loading: false,
        coaches: action.payload.coaches,
        orgTree: action.payload.organization,
        error: null
      };
    }
    case DashboardPageActionTypes.GetOrganizationDataFailure: {
      return {
        ...state,
        loading: false,
        error: true // TODO - Actually pass error
      };
    }
    case DashboardPageActionTypes.CloseSidenav:
      return {
        ...state,
        showSidenav: false,
      };

    case DashboardPageActionTypes.OpenSidenav:
      return {
        ...state,
        showSidenav: true,
      };
    case DashboardPageActionTypes.CoachPinnedStatusChanged: {
      const contains = state.pinnedCoachesIds.includes(action.payload.id);
      return {
        ...state,
        pinnedCoachesIds: contains ? state.pinnedCoachesIds.filter(id => id !== action.payload.id).slice(0) :
        state.pinnedCoachesIds.concat([action.payload.id])
      };
    }
    case DashboardPageActionTypes.BuildOrgTreeComplete: {
      return {
        ...state,
        orgTree: action.payload
      };
    }
    case DashboardPageActionTypes.UpdateCoachFilterValue: {
      return {
        ...state,
        coachFilterValue: action.payload
      };
    }
    case DashboardPageActionTypes.AddSavedGridState: {
      const toAdd = {};
      toAdd[action.payload.name] = action.payload.state;
      return {
        ...state,
        savedQuery: Object.assign({}, state.savedQuery, toAdd)
      };
    }

    case DashboardPageActionTypes.UpdateQueryFilterValue: {
      return {
        ...state,
        savedQueryFilterValue: action.payload
      }
    }
    default:
      return state;
  }
}



export const getShowSidenav = (state: State) => state.showSidenav;

export const getCoaches = (state: State) => {
    return state.coaches;
};

export const getPinned = (state: State) => {
  return state.pinnedCoachesIds;
};

export const getOrgTree = (state: State) => {
  return state.orgTree;
};

export const getCoachFilterValue = (state: State) => {
  return state.coachFilterValue;
};

export const getLoading = (state: State) => {
  return state.loading;
};

export const getQueryFilterValue = (state: State) => {
  return state.savedQueryFilterValue;
}

export const getSavedQueries = (state: State) => {
  return state.savedQuery;
}
