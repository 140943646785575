export interface OptAuthenticate {
  username: string;
  password: string;
}

export interface OptUser {
  username: string;
  token: string;
}

export interface IUser {
  uid:         string;
  displayName: string;
  loading?:    boolean;
  error?:      string;
  token?:      string;
}

export class User implements IUser {
  constructor(public uid: string, public displayName: string, public token: string) {}
}

