import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercentifierPipe } from './percentifier.pipe';
import { DataTablesModule } from 'angular-datatables';
import { SystemStatusGuard } from './services/system-messages-guard.service';
import { AngularFirestoreModule } from 'angularfire2/firestore';

@NgModule({
  imports: [
  CommonModule,
    DataTablesModule,
    AngularFirestoreModule
  ],
  declarations: [PercentifierPipe],
  exports: [PercentifierPipe, DataTablesModule],
  providers: [SystemStatusGuard]
})
export class SharedModule { }
