import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OptLoginPageComponent } from './containers/opt-login-page.component';
import { OptLoginFormComponent } from './components/opt-login-form.component';
import { OptAuthService } from './services/opt-auth.service';
import { OptAuthGuard } from './services/opt-auth-guard.service';
import { OptAuthEffects } from './effects/opt-auth.effects';
import { reducers } from './reducers';
import { OptAuthRoutingModule } from './opt-auth-routing.module';

export const COMPONENTS = [OptLoginPageComponent, OptLoginFormComponent];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class OptAuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: RootAuthModule,
      providers: [OptAuthService, OptAuthGuard],
    };
  }
}

@NgModule({
  imports: [
    OptAuthModule,
    OptAuthRoutingModule,
    StoreModule.forFeature('optauth', reducers),
    EffectsModule.forFeature([OptAuthEffects]),
  ],
})
export class RootAuthModule {}
