import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';
import { FireAnalytics, RootActionTypes, RedirectToSystemMessageScreen } from '../actions/root.actions';

@Injectable()
export class RootEffects {

  @Effect({dispatch:false})
  fireAnalytics$ = this.actions$.pipe(
    ofType<FireAnalytics>(RootActionTypes.FireAnalytics),
    tap((action) => {
      const toSend = JSON.parse(JSON.stringify(action.payload));
      this.angulartics.eventTrack.next(action.payload);
    }));

  @Effect({dispatch: false})
  routeToSystemMessage$ = this.actions$.pipe(
    ofType<RedirectToSystemMessageScreen>(RootActionTypes.RedirectToSystemMessage),
    tap((action) => {
      this.router.navigate(['/system/message']);
    })
  )

  constructor(
    private router: Router,
    private actions$: Actions,
    private angulartics: Angulartics2
  ) {}
}
