import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as fromRoot from '../reducers';
import * as fromAuth from '../opt-auth/reducers';
import { Store } from '@ngrx/store';
import { OptUser } from '../opt-auth/models/user';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OptClearCredentials, ClearCredentials } from './../opt-auth/actions/opt-auth.actions';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

    constructor(private router: Router, private store: Store<fromRoot.State>) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(
                (error: any, caught: Observable<HttpEvent<any>>) => {
                    if ([503].includes(error.status)) {
                        this.store.dispatch(new OptClearCredentials());
                        return of(error);
                    } else if ([403, 401].includes(error.status)) {
                        this.store.dispatch(new OptClearCredentials());
                        this.store.dispatch(new ClearCredentials());
                        return of(error);
                    }
                    throw error;
                }
            ));
    }
}
