import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from './opt-auth.reducer';
import * as fromLoginPage from './opt-login-page.reducer';

export interface OptAuthState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
}

export interface State extends fromRoot.State {
  optauth: OptAuthState;
}

export const reducers: ActionReducerMap<OptAuthState> = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer,
};

export const selectAuthState = createFeatureSelector<State, OptAuthState>('optauth');

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: OptAuthState) => state.status
);
export const getOptLoggedIn = createSelector(
  selectAuthStatusState,
  fromAuth.getOptLoggedIn
);
export const getOptUser = createSelector(selectAuthStatusState, fromAuth.getOptUser);

export const getFbLoggedIn = createSelector(
  selectAuthStatusState,
  fromAuth.getFbLoggedIn
);
export const getFbUser = createSelector(selectAuthStatusState, fromAuth.getFbUser);

export const selectLoginPageState = createSelector(
  selectAuthState,
  (state: OptAuthState) => state.loginPage
);
export const getLoginPageError = createSelector(
  selectLoginPageState,
  fromLoginPage.getError
);
export const getLoginPagePending = createSelector(
  selectLoginPageState,
  fromLoginPage.getPending
);
