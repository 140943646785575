import { Injectable } from '@angular/core';
import { Observable, of, throwError, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

@Injectable()
export class OrganizationService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.urls.organizationBase;

  getOrganizationStatsWithProjected(): Observable<any> {
     return this.http.get(this.baseUrl)
     .pipe(map((response: any) => response));
    // const project = new ReplaySubject(1);
    // setTimeout(() => {
    //   project.next(JSON.parse(JSON.stringify(orgResponse.combined)));
    // }, 5000);

    // return project;
  }
}
