<li class="item" >
  <a class="nav-link"        
    fxLayout
    fxLayoutAlign="end">
  <span fxFlex>{{ coach.firstName }} {{ coach.lastName }}</span> 
  <span fxFlex>
    <label style="float:right"  class="switch switch-label switch-pill switch-success" (click)="onChecked($event)">
      <input  class="switch-input" [checked]="isChecked" [value]="isChecked" type="checkbox">
      <span  class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
    </label>
  </span>
  </a>
</li>
