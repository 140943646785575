import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as AuthActions from '../actions/opt-auth.actions';
import * as fromAuth from '../reducers';

@Injectable()
export class OptAuthGuard implements CanActivate {
  constructor(private store: Store<fromAuth.State>) {}

  canActivate(): Observable<boolean> {
    // return of(true);
    return this.store.pipe(
      select(fromAuth.getOptLoggedIn),
      map(authed => {
        if (!authed) {
          this.store.dispatch(new AuthActions.OptLoginRedirect());
          return false;
        }

        return true;
      }),
      take(1)
    );
  }
}
