import { Component, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Coach } from '../../models/coach';
import { selectCoaches, selectPinnedCoachesIds, selectQueryFilterValue, selectQueriesFilteredByValue } from '../../reducers';
import { combineLatest } from 'rxjs';
import { State } from '../../../reducers';
import { GridOption, Column, FieldType, Filters, AngularGridInstance, GridState, GridStateType } from 'angular-slickgrid';
import flatten from 'flat';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AddSavedGridState, UpdateSavedQueryFilterValue } from './../../actions/dashboard-page.actions';
import { take } from 'rxjs/operators';
import { gvPercentageFormatter } from './formatters/coach-compare';

export interface SavedGridState {
  name: string;
  state: GridState;
}

@Component({
  selector: 'app-tabular-page',
  templateUrl: './tabular-page.component.html',
  styleUrls: ['./tabular-page.component.scss']
})
export class TabularPageComponent implements OnInit {
  hideGrid = false;
  coaches: Coach[] = [];
  pinnedCoachIds: string[];
  columnDefinitions: Column[];
  gridOptions: GridOption;
  hiddenColumns: Column[];
  angularGrid: AngularGridInstance;
  modalRef: BsModalRef;
  filterValue: string;
  loading = false;
  activeState: SavedGridState;
  savedQueries: SavedGridState[];
  saveQueryForm: FormGroup = new FormGroup({
    queryName: new FormControl('', [Validators.required])
  });

  columnPresets: any[];



  constructor(private store: Store<State>, private modalService: BsModalService) {
    this.store.select(selectQueryFilterValue)
      .pipe(take(1))
      .subscribe(val => {
        this.filterValue = val;
      });


    this.store.select(selectQueriesFilteredByValue)
      .subscribe((saved) => {
        this.savedQueries = saved;
      });
  }

  ngOnInit() {
    this.hiddenColumns = [
      { id: 'id', name: 'Coach ID', field: 'id', sortable: true, filterable: true, type: FieldType.string },
      { id: 'hpStatus', name: 'HP Status', field: 'HPStatus', sortable: true, filterable: true, type: FieldType.string },
      { id: 'activationDate', name: 'Activation Date', field: 'activationDate', sortable: true, filterable: true, type: FieldType.dateUtc },
      { id: 'commissionPeriod', name: 'Commission Period', field: 'commissionPeriod', sortable: true, filterable: true, type: FieldType.dateUtc },
      { id: 'globalDirector', name: 'Global Director', field: 'globalDirector', sortable: true },
      { id: 'highestRankNumber', name: 'Highest Rank Number', field: 'highestRankNumber', sortable: true, filterable: true, type: FieldType.number },
      { id: 'presidentialDirector', name: 'Presidential Director', field: 'presidentialDirector', sortable: true, filterable: true, type: FieldType.string },
      // { id: 'teamStats.fiblTeams.current', name: 'Proj Rank', field: 'teamStats.fiblTeams.current', sortable: true, filterable: true, type: FieldType.string },
      // { id: 'teamStats.fiblTeams.projected', name: 'Proj Rank', field: 'teamStats.fiblTeams.projected', sortable: true, filterable: true, type: FieldType.string },
      // { id: 'teamStats.fibcTeams.current', name: 'Proj Rank', field: 'teamStats.fibcTeams.current', sortable: true, filterable: true, type: FieldType.string },
      // { id: 'teamStats.fibcTeams.projected', name: 'Proj Rank', field: 'teamStats.fibcTeams.projected', sortable: true, filterable: true, type: FieldType.string },
      { id: 'email', name: 'E-Mail', field: 'email', sortable: true, filterable: true, type: FieldType.string },
      { id: 'perToNextRank', name: '% to Next Rank', field: 'perToNextRank', sortable: true, filterable: true, type: FieldType.number },
      { id: 'phone', name: 'Phone', field: 'phone', sortable: true, filterable: true, type: FieldType.string },
      { id: 'pv', name: 'PV', field: 'PV', sortable: true, filterable: true, type: FieldType.number },
      { id: 'businessCoachID', name: 'Business Coach ID', field: 'businessCoachID', sortable: true, filterable: true, type: FieldType.string },
      { id: 'level', name: 'Level', field: 'level', sortable: true, filterable: true, type: FieldType.number },
      { id: 'newFrontlineClients', name: 'New Frontline Clients', field: 'newFrontlineClients', sortable: true, filterable: true, type: FieldType.number },
      { id: 'newFrontlineCoaches', name: 'New Frontline Coaches', field: 'newFrontlineCoaches', sortable: true, filterable: true, type: FieldType.number },
      { id: 'certified', name: 'Certified', field: 'certified', sortable: true, filterable: true, type: FieldType.string },
      { id: 'rank.projected', name: 'Proj Rank', field: 'rank.projected', sortable: true, filterable: true, type: FieldType.string },
      {
        id: 'currentRank', name: 'Rank Number', field: 'currentRank', sortable: true, filterable: true, type: FieldType.string, 
      },
      {
        id: 'qualifyingPoints.previous', name: 'QP Prev', field: 'qualifyingPoints.previous', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'frontLineVolume.previousMonth', name: 'FLV Prev', field: 'frontLineVolume.previousMonth', sortable: true, filterable: true, type: FieldType.number,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'groupVolume.previousMonth', name: 'GV Prev', field: 'groupVolume.previousMonth', sortable: true, filterable: true, type: FieldType.number,
        filter: { model: Filters.compoundInput },
      },
      {
        id: 'orderingEntities.previousMonth', name: 'OE Prev', field: 'orderingEntities.previousMonth', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'teamStats.scTeams.previousMonth', name: 'SC Prev', field: 'teamStats.scTeams.previousMonth', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'teamStats.edTeams.previousMonth', name: 'ED Prev', field: 'teamStats.edTeams.previousMonth', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
    ];

    this.columnDefinitions = [
      {
        id: 'firstName', name: 'First Name', field: 'firstName', sortable: true, filterable: true, type: FieldType.string
      },
      {
        id: 'lastName', name: 'Last Name', field: 'lastName', sortable: true, filterable: true, type: FieldType.string
      },
      { id: 'rank.current', name: 'Rank', field: 'rank.current', sortable: true, filterable: true, type: FieldType.string },
      {
        id: 'highestAchievedRank', name: 'Highest Achieved Rank', field: 'highestAchievedRank', sortable: true, filterable: true, type: FieldType.string
      },
      {
        id: 'frontLineVolume.current', name: 'FLV', field: 'frontLineVolume.current', sortable: true, filterable: true, type: FieldType.number,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'frontLineVolume.projected', name: 'Proj FLV', field: 'frontLineVolume.projected', sortable: true, filterable: true, type: FieldType.number,
        filter: { model: Filters.compoundInput }
      },
  
      {
        id: 'groupVolume.current', name: 'GV', field: 'groupVolume.current', sortable: true, filterable: true, type: FieldType.number,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'groupVolume.projected', name: 'Proj GV', field: 'groupVolume.projected', sortable: true, filterable: true, type: FieldType.number,
        filter: { model: Filters.compoundInput },
      },
      {
        id: 'groupVolumePercentage', name: 'GV %', field: 'groupVolumePercentage', sortable: true, filterable: true, type: FieldType.number,
        filter: { model: Filters.compoundInput }, formatter: gvPercentageFormatter,
      },
      {
        id: 'orderingEntities.current', name: 'OE', field: 'orderingEntities.current', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'orderingEntities.projected', name: 'Proj OE', field: 'orderingEntities.projected', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
     
      {
        id: 'qualifyingPoints.current', name: 'QP', field: 'qualifyingPoints.current', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'qualifyingPoints.projected', name: 'Proj QP', field: 'qualifyingPoints.projected', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'teamStats.scTeams.current', name: 'SC Teams', field: 'teamStats.scTeams.current', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'teamStats.scTeams.projected', name: 'SC Proj', field: 'teamStats.scTeams.projected', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
     
      {
        id: 'teamStats.edTeams.current', name: 'ED Teams', field: 'teamStats.edTeams.current', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
      {
        id: 'teamStats.edTeams.projected', name: 'ED Proj', field: 'teamStats.edTeams.projected', sortable: true, filterable: true, type: FieldType.number, maxWidth: 100,
        filter: { model: Filters.compoundInput }
      },
     
      {
        id: 'businessCoachName', name: 'Business Coach Name', field: 'businessCoachName', sortable: true, filterable: true, type: FieldType.string, minWidth: 100
      },
      {
        id: 'clientSupportCommissions.current', name: 'Current Commissions', field: 'clientSupportCommissions.current', sortable: true, filterable: true, 
        type: FieldType.number, maxWidth: 125, filter: { model: Filters.compoundInput }
      },
      {
        id: 'clientSupportCommissions.projected', name: 'Proj Commissions', field: 'clientSupportCommissions.projected', sortable: true, filterable: true, 
        type: FieldType.number, maxWidth: 125, filter: { model: Filters.compoundInput }
      },
      {
        id: 'nextFLVTierClientSupportCommissions', name: 'Next FLV Tier Commissions', field: 'nextFLVTierClientSupportCommissions', sortable: true, filterable: true, 
        type: FieldType.number, maxWidth: 125, filter: { model: Filters.compoundInput }
      },
    ];

    this.columnPresets = this.columnDefinitions.map(c => {
      return {
        columnId: c.id
      }
    });

    this.columnDefinitions = this.columnDefinitions.concat(this.hiddenColumns);

    this.gridOptions = {
      enableAutoResize: true,
      enableFiltering: true,
      autoResize: {
        containerId: 'my-grid-container',
        sidePadding: 15
      },
      presets: {
        columns: this.columnPresets
      },
      enableCellNavigation: true
    };

    combineLatest(this.store.select(selectCoaches), this.store.select(selectPinnedCoachesIds))
      .subscribe(([coaches, pinnedIds]) => {
        // TODO: SORT BASED ON PIN AND NAME

        this.coaches = JSON.parse(JSON.stringify(coaches));
        this.coaches = this.coaches.map((coach) => flatten(coach));
        this.coaches = this.coaches.map((coach) => {
          Object.keys(coach).forEach(key => {
            coach[key] = coach[key] === null ? '' : coach[key];
          });
          return coach;
        });

        console.log(this.coaches[0]);
        this.pinnedCoachIds = pinnedIds;
      });
  }

  angularGridReady(angularGrid: any) {
    this.angularGrid = angularGrid;
  }

  saveCurrentGridState(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  confirm(): void {
    const gridState: GridState = this.angularGrid.gridStateService.getCurrentGridState();
    console.log('Leaving page with current grid state', gridState);
    console.log(this.saveQueryForm.value);
    this.store.dispatch(new AddSavedGridState({ name: this.saveQueryForm.value.queryName, state: gridState }));
    this.modalRef.hide();
  }

  decline(): void {
    this.saveQueryForm.get('queryName').setValue('');
    this.modalRef.hide();
  }

  filterValueChange($event) {
    this.filterValue = $event;
    this.store.dispatch(new UpdateSavedQueryFilterValue($event));
  }

  clearFilterValue() {
    this.filterValue = "";
    this.store.dispatch(new UpdateSavedQueryFilterValue(""));
  }

  activateState(savedState: SavedGridState) {
    this.activeState = savedState;
    this.gridOptions.presets = savedState.state;
    this.gridOptions = JSON.parse(JSON.stringify(this.gridOptions));
    this.angularGrid.slickGrid.setOptions(this.gridOptions);
    this.rerenderGrid();
  }

  rerenderGrid() {
    this.hideGrid = true;
    setTimeout(() => {
      this.hideGrid = false;
    }, 0);
  }
}
