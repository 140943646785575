import { Action } from '@ngrx/store';

export enum RootActionTypes {
  FireAnalytics = '[Root] Fire Analytics',
  RedirectToSystemMessage = '[Root] Redirect to System Message',
}

export class FireAnalytics implements Action {
  readonly type = RootActionTypes.FireAnalytics;
  constructor(public payload: {action: string; properties: any}) {}
}

export class RedirectToSystemMessageScreen implements Action {
  readonly type = RootActionTypes.RedirectToSystemMessage;
  constructor () {}
}


export type RootActionTypesUnion =
FireAnalytics;
