
import { Formatter, Column } from 'angular-slickgrid';

export const gvPercentageFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any): string => {
  if (value === null || value === '' || value === undefined) {
    return '';
  }

  let color;

  if (-5 >= dataContext.myGVPercentageDiff ) {
    color = 'red';
  } else if (dataContext.myGVPercentageDiff >= 5) {
    color = 'green';
  } else {
    color = 'orange';
  }

  return `<div style="background-color:${color}; color: white; width:100%; height:100%">${value}</div>`;
};