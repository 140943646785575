import { Injectable } from '@angular/core';
import { 
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument 
} from 'angularfire2/firestore';
import { SystemMessage } from './SystemMessage';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class SystemMessagesService {

  messagesCollection: AngularFirestoreCollection<SystemMessage>;
  messages: Observable<SystemMessage[]>;
  messageDoc: AngularFirestoreDocument<SystemMessage>;

  constructor(public afs:AngularFirestore) {
    this.messagesCollection = this.afs.collection('system_message');
    // this.messages = this.afs.collection('messages').valueChanges();
    this.messages = this.messagesCollection.snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data() as SystemMessage;
        data.id = a.payload.doc.id;
        return data;
      });
    });
  }

  getSystemMessages() {
    return this.messages; 
  }

}
