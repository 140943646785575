<ul class="list">
  <div class="relative-wrapper" *ngIf="!loading">
    <input [ngModel]="filterValue" (ngModelChange)="filterValueChange($event)" id="header_search_query" type="search" name="header_search_query"
      value="" placeholder="Filter coaches..." class="header-search-input">
    <button *ngIf="!filterValue" type="submit" name="search_submit" class="search-button">
      <i class="icon-magnifier icons d-block"></i>
    </button>
    <button (click)="clearFilterValue()" *ngIf="filterValue" type="submit" name="search_submit" class="search-button">
        <i style="font-style: normal" class="icons d-block">✕</i>
      </button>
  </div>

  <div class="ph-item no-animation no-background no-margin" *ngIf="loading">
    <div class="ph-col-12">
      <div class="ph-row">
        <div class="ph-col-12 big"></div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <app-nav-coach-list-item (toggled)="coachToggleChanged($event)" [isChecked]="pinned.includes(coach.id)" [coach]="coach" *ngFor="let coach of coaches"></app-nav-coach-list-item>
  </ng-container>

  <div class="ph-item no-animation no-background no-margin" *ngIf="loading">
    <div class="ph-col-12">
      <div class="ph-row">
        <div class="ph-col-8 big"></div>
        <div class="ph-col-2 big empty"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-4"></div>
        <div class="ph-col-8 empty"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12 big empty"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-8 big"></div>
        <div class="ph-col-2 big empty"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-4"></div>
        <div class="ph-col-8 empty"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12 big empty"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-8 big"></div>
        <div class="ph-col-2 big empty"></div>
        <div class="ph-col-2 big"></div>
        <div class="ph-col-4"></div>
        <div class="ph-col-8 empty"></div>
      </div>
      <div class="ph-row">
        <div class="ph-col-12 big empty"></div>
      </div>
    </div>
  </div>
</ul>