import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, combineLatest } from 'rxjs';
import * as fromRoot from '../reducers';
import * as fromOptAuth from '../opt-auth/reducers';
import { Store } from '@ngrx/store';
import { take, flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private store: Store<fromRoot.State>) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest(
      this.store.select(fromOptAuth.getOptUser),
      this.store.select(fromOptAuth.getFbUser)
    ).pipe(
        take(1),
        flatMap( ([user, details]) => {
          let authReq = (user && !!user.token) ? req.clone({
            setHeaders: { 'x-slay-api-key':  user.token, 'x-slay-user': user.username },
          }) : req;

          authReq = (details && !!details.token) ? authReq.clone({
            setHeaders: { 'Authorization':  `Bearer ${details.token}` },
          }) : authReq;
          return next.handle(authReq);
        }),
      );
  }
}
