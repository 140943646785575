<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-5 mx-auto">
                    <slay-opt-login-form [fbLoggedIn]="fbLoggedIn$" (fbSignIn)="signInWithFacebook()" (submitted)="onSubmit($event)" [pending]="(pending$ | async)" [errorMessage]="error$ | async">
                    </slay-opt-login-form>
                </div>
            </div>
        </div>
    </main>
</div>