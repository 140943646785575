import {
    createSelector,
    createFeatureSelector,
    ActionReducerMap,
} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromDashboardPage from './dashboard-page.reducer';

export interface DashboardState {
    dashboardPage: fromDashboardPage.State;
}

export interface State extends fromRoot.State {
    dashboard: DashboardState;
}

export const reducers: ActionReducerMap<DashboardState> = {
    dashboardPage: fromDashboardPage.reducer,
};

export const selectDashboardState = createFeatureSelector<State, DashboardState>('dashboard');

export const selectDashboardPageState = createSelector(selectDashboardState, (state: DashboardState) => state.dashboardPage);

export const selectCoaches = createSelector(selectDashboardPageState, fromDashboardPage.getCoaches);

export const selectShowSideNav = createSelector(selectDashboardPageState, fromDashboardPage.getShowSidenav);

export const selectPinnedCoachesIds = createSelector(selectDashboardPageState, fromDashboardPage.getPinned);

// returning a copy here because these coaches will be extended with org data
export const selectPinnedCoaches = createSelector(selectCoaches, selectPinnedCoachesIds, (coaches, pinned) => {
    return JSON.parse(JSON.stringify(coaches.filter((c) => pinned.includes(c.id))));
});

export const selectOrgTree = createSelector(selectDashboardPageState, fromDashboardPage.getOrgTree);

export const selectCoachFilterValue = createSelector(selectDashboardPageState, fromDashboardPage.getCoachFilterValue);

export const  selectCoachesFilteredByValue = createSelector(selectCoaches, selectCoachFilterValue, (coaches, filterValue) => {
    if (!filterValue) {
        return coaches;
    }

    return coaches.filter(c => {
        return `${c.firstName} ${c.lastName}`.toLowerCase().includes(filterValue.toLowerCase());
    });
});

export const selectIsLoading = createSelector(selectDashboardPageState, fromDashboardPage.getLoading);

export const selectQueryFilterValue = createSelector(selectDashboardPageState, fromDashboardPage.getQueryFilterValue);

export const selectQueries = createSelector(selectDashboardPageState, fromDashboardPage.getSavedQueries);

export const  selectQueriesFilteredByValue = createSelector(selectQueries, selectQueryFilterValue, (queries, filterValue) => {
    const objectToArray = (obj) => {
        return Object.keys(obj).map(key => {
            return {
                name: key,
                state: obj[key]
            }
        });
    }
    if (!filterValue) {
        return objectToArray(queries);
    }

    return objectToArray(queries).filter(c => {
        return c.name.toLowerCase().includes(filterValue.toLowerCase());
    });
});