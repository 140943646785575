import { Action } from '@ngrx/store';
import { OptUser, OptAuthenticate, User } from '../models/user';

export enum OptAuthActionTypes {
  OptLogin = '[Opt Auth] Login',
  OptLogout = '[Opt Auth] Logout',
  OptLoginSuccess = '[Opt Auth] Login Success',
  OptLoginFailure = '[Opt Auth] Login Failure',
  OptLoginRedirect = '[Opt Auth] Login Redirect',
  OptClearCredentials = '[Opt Auth] Clear Credentials',

  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  LoginSuccess = '[Auth] Login Success',
  LoginFailure = '[Auth] Login Failure',
  LoginRedirect = '[Auth] Login Redirect',
  ClearCredentials = '[Auth] Clear Credentials',
  GetUser = '[Auth] Get Firebase User'
}

export class OptLogin implements Action {
  readonly type = OptAuthActionTypes.OptLogin;

  constructor(public payload: OptAuthenticate) {}
}

export class OptLoginSuccess implements Action {
  readonly type = OptAuthActionTypes.OptLoginSuccess;

  constructor(public payload: { user: OptUser }) {}
}

export class OptLoginFailure implements Action {
  readonly type = OptAuthActionTypes.OptLoginFailure;

  constructor(public payload: any) {}
}

export class OptLoginRedirect implements Action {
  readonly type = OptAuthActionTypes.OptLoginRedirect;
}

export class OptLogout implements Action {
  readonly type = OptAuthActionTypes.OptLogout;
}

export class OptClearCredentials implements Action {
  readonly type = OptAuthActionTypes.OptClearCredentials;
}

export class Login implements Action {
  readonly type = OptAuthActionTypes.Login;
}

export class LoginSuccess implements Action {
  readonly type = OptAuthActionTypes.LoginSuccess;

  constructor(public payload: User) {}
}

export class LoginFailure implements Action {
  readonly type = OptAuthActionTypes.LoginFailure;

  constructor(public payload: any) {}
}

export class LoginRedirect implements Action {
  readonly type = OptAuthActionTypes.LoginRedirect;
}

export class Logout implements Action {
  readonly type = OptAuthActionTypes.Logout;
}

export class ClearCredentials implements Action {
  readonly type = OptAuthActionTypes.ClearCredentials;
}

export class GetUser implements Action {
  readonly type = OptAuthActionTypes.GetUser;
  constructor(public payload?: any) {}
}

export type OptAuthActionsUnion =
  | OptLogin
  | OptLoginSuccess
  | OptLoginFailure
  | OptLoginRedirect
  | OptLogout
  | OptClearCredentials
  | Login
  | LoginSuccess
  | LoginFailure
  | LoginRedirect
  | Logout
  | GetUser
  | ClearCredentials;
