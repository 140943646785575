import { Action } from '@ngrx/store';
import { Coach } from '../models/coach';
import { GridState } from 'angular-slickgrid';

export enum DashboardPageActionTypes {
  OpenSidenav = '[Dashboard Page] Open Sidenav',
  CloseSidenav = '[Dashboard Page] Close Sidenav',
  GetOrganizationData = '[Dashboard Page] Get Organization Data',
  GetOrganizationDataSuccess = '[Dashboard Page] Get Organization Data Success',
  GetOrganizationDataFailure = '[Dashboard Page] Get Organization Data Failure',
  CoachPinnedStatusChanged = '[Dashboard Page] Coach Pinned Status Changed',
  BuildOrgTree = '[Dashboard Page] Build Org Tree',
  BuildOrgTreeComplete = '[Dashboard Page] Build Org Tree Complete',
  UpdateCoachFilterValue = '[Dashboard Page] Update Coach Filter Value',

  // these should be moved out into their own reducer
  AddSavedGridState = '[Dashboard Page Table] Add Saved Grid State',
  UpdateQueryFilterValue = '[Dashboard Page Table] Update Saved Query Filter Value',
}

export class OpenSidenav implements Action {
  readonly type = DashboardPageActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
  readonly type = DashboardPageActionTypes.CloseSidenav;
}

export class GetOrganizationData implements Action {
  readonly type = DashboardPageActionTypes.GetOrganizationData;
}

export class GetOrganizationDataSuccess implements Action {
  readonly type = DashboardPageActionTypes.GetOrganizationDataSuccess;
  constructor(public payload: { coaches: Coach[], organization: any }) {}
}

export class GetOrganizationDataFailure implements Action {
  readonly type = DashboardPageActionTypes.GetOrganizationDataFailure;
  constructor(public payload: any) {}
}

export class CoachPinnedStatusChanged implements Action {
  readonly type = DashboardPageActionTypes.CoachPinnedStatusChanged;
  constructor(public payload: Coach) {}
}

export class BuildOrgTree implements Action {
  readonly type = DashboardPageActionTypes.BuildOrgTree;
  constructor(public payload: Coach[]) {}
}

export class BuildOrgTreeComplete implements Action {
  readonly type = DashboardPageActionTypes.BuildOrgTreeComplete;
  constructor(public payload: any) {}
}

export class UpdateListFilterValue implements Action {
  readonly type = DashboardPageActionTypes.UpdateCoachFilterValue;
  constructor(public payload: string) {}
}

export class AddSavedGridState implements Action {
  readonly type = DashboardPageActionTypes.AddSavedGridState;
  constructor(public payload: {name: string, state: GridState}) {}
}

export class UpdateSavedQueryFilterValue implements Action {
  readonly type = DashboardPageActionTypes.UpdateQueryFilterValue;
  constructor(public payload: string) {}
}

export type DashboardPageActionsUnion =
  OpenSidenav |
  CloseSidenav |
  GetOrganizationData |
  GetOrganizationDataSuccess |
  CoachPinnedStatusChanged |
  GetOrganizationDataFailure |
  BuildOrgTree |
  BuildOrgTreeComplete |
  AddSavedGridState |
  UpdateSavedQueryFilterValue |
  UpdateListFilterValue;
