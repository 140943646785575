import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Coach } from '../../../models/coach';

@Component({
  selector: 'app-nav-coach-list-item',
  templateUrl: './nav-coach-list-item.component.html',
  styleUrls: ['./nav-coach-list-item.component.scss']
})
export class NavCoachListItemComponent implements OnInit {
  @Input() coach: Coach;
  @Input() isChecked: boolean;
  @Output() toggled: EventEmitter<Coach> = new EventEmitter<Coach>();
  constructor() { }

  ngOnInit() {
  }

  onChecked(event: MouseEvent) {
    this.toggled.emit(this.coach);
    event.preventDefault();
  }
}
