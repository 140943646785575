<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-nav-coach-list [filterValue]="filterValue" (coachFilterChanged)="handleFilterChange($event)" [coaches]="coaches" [pinned]="pinned" [loading]="(loading$ | async)" (coachToggled)="handleCoachToggled($event, true)"
      (coachSelected)="handleCoachSelected($event)"></app-nav-coach-list>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <app-card-list>
          <div class="col-sm-6 col-lg-6 col-md-6 col-xl-3" *ngFor="let coach of pinnedCoaches">
            <div class="ph-item" *ngIf="(loading$ | async)">
              <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-8 big"></div>
                    <div class="ph-col-4 big empty"></div>
                </div>
              </div>
              <div class="ph-col-4">
                <div class="ph-picture"></div>
              </div>
              <div class="ph-col-4">
                <div class="ph-picture"></div>
              </div>
              <div class="ph-col-4">
                <div class="ph-picture"></div>
              </div>
              <div class="ph-col-12">
                <div class="ph-row">
                  <div class="ph-col-4"></div>
                  <div class="ph-col-8 empty"></div>
                  <div class="ph-col-12"></div>
                </div>
              </div>

              <div>
                <div class="ph-row">
                  <div class="ph-col-12"></div>
                  <div class="ph-col-2"></div>
                  <div class="ph-col-10 empty"></div>
                </div>
              </div>
            </div>
            <app-pinned-coach-card (unpinned)="handleCoachToggled($event, false)" *ngIf="!(loading$ | async)" [coach]="coach"></app-pinned-coach-card>
          </div>
        </app-card-list>
      </div>
    </div>
    <!-- /.container-fluid -->
  </main>
</div>