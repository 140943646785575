import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styles: [`
    .navbar {
      justify-content: flex-start;
      position: fixed;
      z-index: 1020;
      width: 100%;
    }

    @media (min-width: 991.98px) {
      .app-header .navbar-brand {
          margin-left: 20px;
      }
    }

    .navbar-toggler {
      padding:20px;
    }

    :host /deep/ .app-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  `]
})
export class DefaultLayoutComponent implements OnDestroy {
  public sidebarShown = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  constructor() {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarShown = document.body.classList.contains('sidebar-show');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
  }

  sideNavToggleClicked(clazz: string) {
    document.body.classList.contains(clazz) ?  document.body.classList.remove(clazz) : document.body.classList.add(clazz);
  }

  ngOnDestroy() {
    document.body.className = '';
  }
}
