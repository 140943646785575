import { Component, Input } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import * as fromDashboard from '../reducers/dashboard-page.reducer';
import { Store } from '@ngrx/store';
import { GetOrganizationData, CoachPinnedStatusChanged, UpdateListFilterValue } from '../actions/dashboard-page.actions';
import { Coach } from '../models/coach';
import { selectCoaches, selectPinnedCoachesIds, selectOrgTree, selectCoachesFilteredByValue, selectIsLoading, selectCoachFilterValue } from '../reducers';
import { State } from '../../reducers';
import { selectShowSideNav, selectPinnedCoaches } from './../reducers/index';
import { searchTree } from '../../utils';
import { take } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { FireAnalytics } from '../../actions/root.actions';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-page.component.html',
  styles: [`
    app-nav-coach-list {
      width: 100%;
      height: 100%;
    }

    .app-body {
      margin-top: 55px;
    }
  `]
})
export class DashboardPageComponent {
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public coaches: Coach[];
  public pinnedCoaches: Coach[] = [];
  public pinned: any[];
  public loading$: Observable<boolean>;
  public filterValue: string;

  constructor(private store: Store<State>) {

    this.store.select(selectCoachFilterValue)
      .pipe(take(1))
      .subscribe(val => {
        this.filterValue = val;
      });
    // TODO - clean up sub or bind with async pipe?
    this.store.select(selectShowSideNav)
      .subscribe((showSideNav: boolean) => this.sidebarMinimized = !showSideNav);

    this.store.select(selectCoachesFilteredByValue)
      .subscribe((coaches) => {
        this.coaches = coaches;
      });

    this.loading$ = this.store.select(selectIsLoading);

    combineLatest(this.store.select(selectPinnedCoachesIds), this.store.select(selectOrgTree))
      .subscribe(([pinned, orgTree]) => {
        this.store.dispatch(new FireAnalytics({
          action: 'PinnedPrep', properties: {
            category: 'DashboardCards', label: `${pinned.length}/${this.coaches.length}`
          }
        }));
        this.pinned = pinned;
        this.pinnedCoaches = pinned.map(id => {
          return searchTree(orgTree, (node: Coach) => {
            return id === node.id;
          });
        });
        this.pinnedCoaches = JSON.parse(JSON.stringify(this.pinnedCoaches));
       
        // This is awful, definitely need to refactor --- all of this stuff should be calculatable
        // when the projected group volume is beeing calculated for the org
        this.pinnedCoaches.forEach(coach => {
          coach.children = coach.children ? coach.children : [];
          coach.children = coach.children.sort((a, b) => {
            return b.groupVolume.projected - a.groupVolume.projected;
          }).slice(0, 5);
        });
      });

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });

    this.store.dispatch(new GetOrganizationData());
  }

  handleCoachSelected(coach: Coach) {

  }

  handleCoachToggled(coach: Coach, nav: boolean) {
    this.store.dispatch(new CoachPinnedStatusChanged(coach));
    this.store.dispatch(new FireAnalytics({
      action: 'NavCoachToggle', properties: {
        category: nav ? 'CoachList': 'CoachCard'
      }
    }));
  }

  handleFilterChange(filter: any) {
    this.store.dispatch(new UpdateListFilterValue(filter.value));
    
    const action = filter.value ? 'NavCoachFilter' : 'NavCoachFilterCleared';
    const properties: any = {
      category: 'CoachList'
    };

    if(action === 'NavCoachFilterCleared') {
      properties.label = `triggered by: ${filter.trigger}`
    }

    this.store.dispatch(new FireAnalytics({
      action, properties
    }))
  }
}
