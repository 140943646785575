import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { OptAuthenticate } from '../models/user';

@Component({
  selector: 'slay-opt-login-form',
  templateUrl: './opt-login-form.component.html'
})
export class OptLoginFormComponent implements OnInit {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() fbLoggedIn: any;
     

  @Input() errorMessage: string | null;

  @Output() submitted = new EventEmitter<OptAuthenticate>();
  @Output() fbSignIn = new EventEmitter<any>();
  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  constructor() {}

  ngOnInit() {
    this.fbLoggedIn.subscribe(loggedIn => {
      if (loggedIn) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    });
  }

  submit() {
    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
  }

  signInWithFacebook() {
    this.fbSignIn.emit();
  }
}
