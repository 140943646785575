import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Coach } from '../../models/coach';

@Component({
  selector: 'app-nav-coach-list',
  templateUrl: './nav-coach-list.component.html',
  styleUrls: ['./nav-coach-list.component.scss']
})
export class NavCoachListComponent implements OnInit {
  @Input() coaches: Coach[];
  @Input() pinned: any[];
  @Input() loading;
  @Input() filterValue: string;
  @Output() coachSelected: EventEmitter<Coach> = new EventEmitter<Coach>();
  @Output() coachToggled: EventEmitter<Coach> = new EventEmitter<Coach>();
  @Output() coachFilterChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  coachClicked(coach: Coach) {
    this.coachSelected.emit(coach);
  }

  coachToggleChanged(coach: Coach) {
    this.coachToggled.emit(coach);
  }

  filterValueChange(event) {
    this.filterValue = event;
    this.coachFilterChanged.emit({value: event, trigger: 'typing'});
  }

  clearFilterValue() {
    this.filterValue = '';
    this.coachFilterChanged.emit({value: '', trigger: 'button'});
  }
}
