import { OptAuthActionsUnion, OptAuthActionTypes } from './../actions/opt-auth.actions';
import { OptUser, User } from '../models/user';


export interface State {
  optLoggedIn: boolean;
  optUser: OptUser | null;
  fbLoggedIn: boolean;
  fbUser: User | null;
}

export const initialState: State = {
  optLoggedIn: false,
  optUser: null,
  fbLoggedIn: null,
  fbUser: null
};

export function reducer(state = initialState, action: OptAuthActionsUnion): State {
  switch (action.type) {
    case OptAuthActionTypes.OptLoginSuccess: {
      return {
        ...state,
        optLoggedIn: true,
        optUser: action.payload.user,
      };
    }

    case OptAuthActionTypes.OptClearCredentials:
    case OptAuthActionTypes.OptLogout: {
      return initialState;
    }

    case OptAuthActionTypes.LoginSuccess: {
      return {
        ...state,
        fbLoggedIn: true,
        fbUser: action.payload,
      };
    }

    case OptAuthActionTypes.ClearCredentials:
    case OptAuthActionTypes.Logout: {
      return initialState;
    }


    default: {
      return state;
    }
  }
}

export const getOptLoggedIn = (state: State) => state.optLoggedIn;
export const getOptUser = (state: State) => state.optUser;

export const getFbLoggedIn = (state: State) => state.fbLoggedIn;
export const getFbUser = (state: State) => state.fbUser;
