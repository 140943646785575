<div class="card p-4">
    <div class="card-body">
        <h1>Login</h1>
        <p class="text-muted">Sign In to your account</p>
        <ng-container *ngIf="!(fbLoggedIn | async)">
            <div class="input-group mb-3">
                <button (click)="signInWithFacebook()" class="btn btn-brand btn-lg btn-facebook" style="width:100%; text-align: left" type="button"><i
                        class="fa fa-facebook"></i><span>Continue with Facebook</span></button>
            </div>
            <p>You must sign in with Facebook before entering your username and password</p>
        </ng-container>
        
        <form (ngSubmit)="submit()" [formGroup]="form">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <i class="icon-user"></i>
                    </span>
                </div>
                <input formControlName="username" type="text" class="form-control form-control-lg" placeholder="Username">
            </div>
            <div class="input-group mb-4">
                <div class="input-group-prepend">
                    <span class="input-group-text">
                        <i class="icon-lock"></i>
                    </span>
                </div>
                <input formControlName="password" type="password" class="form-control form-control-lg" placeholder="Password">
            </div>
            <div class="row">
                <div class="col-6">
                    <button type="submit" class="btn btn-primary btn-lg px-4">Login</button>
                </div>
            </div>
        </form>

    </div>
</div>