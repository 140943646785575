import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './default-layout';


import { OptAuthGuard } from './opt-auth/services/opt-auth-guard.service';
import { PrivacyComponent } from './privacy/privacy.component';
import { TabularPageComponent } from './dashboard/containers/tabular-page/tabular-page.component';
import { SystemStatusGuard } from './shared/services/system-messages-guard.service';
import { SystemMessageComponent } from './system-message/system-message.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [OptAuthGuard, SystemStatusGuard]
      },
      {
        path: 'system/message',
        component: SystemMessageComponent,
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
