<div class="card">
  <div class="card-body">
    <div fxLayout="row" fxLayoutAlign="start start">
      <div class="h5 m-0" fxFlex="1 1 auto">
        {{coach.firstName}} {{coach.lastName}}
        <span *ngIf="!showEDBadge(coach)" class="badge badge-pill {{getSCBadgeClass(coach)}}">SC</span>
        <span *ngIf="showEDBadge(coach)" class="badge badge-pill {{getEDBadgeClass(coach)}}">ED</span>
      </div>

      <div class="close" style="text-align:right" fxFlex>
        <button (click)="unpinCoach(coach)">
          &#10005;
        </button>
      </div>
    </div>
    <div fxLayout fxLayoutAlign="center" style="margin-top:1rem">
      <div fxFlex class="text-center">
        <div class="text-muted small text-uppercase font-weight-bold">Frontline</div>
        <div class="py-2">
          <div title="projected" class="h5" style="margin-bottom:0">{{coach.frontLineVolume.projected | currency}}</div>
          <div title="to date" class="text-muted small text-uppercase font-weight-bold">{{coach.frontLineVolume.current |
            currency}}</div>
        </div>
      </div>
      <div fxFlex class="text-center">
        <div class="text-muted small text-uppercase font-weight-bold">Group</div>
        <div class="py-2">
          <div title="projected" class="h5" style="margin-bottom:0">{{coach.groupVolume.projected | currency}}</div>
          <div title="to date" class="text-muted small text-uppercase font-weight-bold">{{coach.groupVolume.current | currency}}</div>
        </div>
      </div>
    </div>
    <div fxLayout fxLayoutAlign="center" style="margin-top:1rem">
    <div fxFlex class="text-center">
        <div class="text-muted small text-uppercase font-weight-bold">SC Teams</div>
        <div class="py-2">
          <div title="projected" class="h5" style="margin-bottom:0">{{coach.teamStats.scTeams.projected}}</div>
          <div title="to date" class="text-muted small text-uppercase font-weight-bold">{{coach.teamStats.scTeams.current}}</div>
        </div>
      </div>
      <div fxFlex class="text-center">
        <div class="text-muted small text-uppercase font-weight-bold">QP</div>
        <div class="py-2">
          <div title="projected" class="h5" style="margin-bottom:0">{{coach.qualifyingPoints.projected}}</div>
          <div title="to date" class="text-muted small text-uppercase font-weight-bold">{{coach.qualifyingPoints.current}}</div>
        </div>
      </div>
</div>
    <div class="bar-group" *ngFor="let child of coach.children">
      <div fxLayout fxLayoutAlign="end">
        <small fxFlex>{{child.firstName}} {{child.lastName}}</small>
        <small [ngStyle]="{color: getTextColor(child)}" style="text-align: right" fxFlex>{{child.groupVolume.current | currency}}
          / {{child.groupVolume.projected | currency}} </small>
      </div>

      <div style="height:7px; margin-top:.25rem; margin-bottom: .5rem" class="progress">
        <div [ngClass]="getColorClass(child)" title="Actual {{child.groupVolume.current | currency}}" aria-valuemax="100" aria-valuemin="0"
          [ngStyle]="{'width.%': getBarValue(child.groupVolume)}" class="progress-bar" role="progressbar"></div>
      </div>
    </div>
  </div>
</div>