import { OptAuthActionTypes, OptAuthActionsUnion } from './../actions/opt-auth.actions';

export interface State {
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false,
};

export function reducer(state = initialState, action: OptAuthActionsUnion): State {
  switch (action.type) {
    case OptAuthActionTypes.OptLogin: {
      return {
        ...state,
        error: null,
        pending: true,
      };
    }

    case OptAuthActionTypes.OptLoginSuccess: {
      return {
        ...state,
        error: null,
        pending: false,
      };
    }
    case OptAuthActionTypes.OptLoginFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }
    case OptAuthActionTypes.OptClearCredentials: {
      return {
        ...state,
        pending: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
