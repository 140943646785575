// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  gaCode: 'UA-124349980-2',
  production: false,
  urls: {
    authBase: 'https://us-central1-slay-dev.cloudfunctions.net/auth/',
    organizationBase: 'https://us-central1-slay-dev.cloudfunctions.net/organization/'
  },
  firebase:{
    apiKey: "AIzaSyC_h60d0dhwSCMXo3PDbWOlhw_6j9MbmWI",
    authDomain: "slay-dev.firebaseapp.com",
    databaseURL: "https://slay-dev.firebaseio.com",
    projectId: "slay-dev",
    storageBucket: "slay-dev.appspot.com",
    messagingSenderId: "30519365621"
  }
};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
