<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <ul class="list">
      <div class="relative-wrapper" *ngIf="!loading">
        <input [ngModel]="filterValue" (ngModelChange)="filterValueChange($event)" id="header_search_query" type="search" name="header_search_query"
          value="" placeholder="Filter saved queries..." class="header-search-input">
        <button *ngIf="!filterValue" type="submit" name="search_submit" class="search-button">
          <i class="icon-magnifier icons d-block"></i>
        </button>
        <button (click)="clearFilterValue()" *ngIf="filterValue" type="submit" name="search_submit" class="search-button">
          <i style="font-style: normal" class="icons d-block">✕</i>
        </button>
      </div>

      <div class="ph-item no-animation no-background no-margin" *ngIf="loading">
        <div class="ph-col-12">
          <div class="ph-row">
            <div class="ph-col-12 big"></div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!loading">
        <li class="item" *ngFor="let query of savedQueries">
          <a class="nav-link" [ngClass]="{'active': query.name === activeState?.name}" fxLayout fxLayoutAlign="end" (click)="activateState(query)">
            <span fxFlex>{{query.name }}</span>
          </a>
        </li>

      </ng-container>

      <div class="ph-item no-animation no-background no-margin" *ngIf="loading">
        <div class="ph-col-12">
          <div class="ph-row">
            <div class="ph-col-8 big"></div>
            <div class="ph-col-2 big empty"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-12 big empty"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-8 big"></div>
            <div class="ph-col-2 big empty"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-12 big empty"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-8 big"></div>
            <div class="ph-col-2 big empty"></div>
            <div class="ph-col-2 big"></div>
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-12 big empty"></div>
          </div>
        </div>
      </div>
    </ul>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <div class="container-fluid">
      <div class="animated fadeIn">
        <div id="my-grid-container" class="row" style="padding-top: 1.25rem">
          <angular-slickgrid *ngIf="!hideGrid" (onAngularGridCreated)="angularGridReady($event)" gridId="grid1" [columnDefinitions]="columnDefinitions"
            [gridOptions]="gridOptions" [dataset]="coaches">
          </angular-slickgrid>
        </div>
        <button (click)="saveCurrentGridState(template)" class="btn btn-block btn-primary floater" type="button">Save</button>
      </div>
    </div>
  </main>

  <ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Enter saved query name</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&#10005;</span>
      </button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="confirm()" [formGroup]="saveQueryForm">
        <div class="input-group mb-3">
          <input formControlName="queryName" type="text" class="form-control form-control-lg" placeholder="Save query as">
        </div>
        <div class="row">
          <div class="col-6">
            <button type="button" [disabled]="!saveQueryForm.valid" class="btn btn-primary" type="submit">Save</button>
            <button type="button" class="btn btn-default" (click)="decline()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>