import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, exhaustMap, map, tap, combineLatest, switchMap } from 'rxjs/operators';
import * as firebase from 'firebase';

import {
  OptAuthActionTypes,
  OptLogin,
  OptLoginFailure,
  OptLoginSuccess,
  GetUser,
  LoginSuccess,
  Login, LoginFailure, ClearCredentials
} from '../actions/opt-auth.actions';
import { OptAuthenticate, User } from '../models/user';
import { OptAuthService } from '../services/opt-auth.service';
import { AngularFireAuth } from 'angularfire2/auth';

@Injectable()
export class OptAuthEffects {
  @Effect()
  loginOpt$ = this.actions$.pipe(
    ofType<OptLogin>(OptAuthActionTypes.OptLogin),
    map(action => action.payload),
    exhaustMap((auth: OptAuthenticate) =>
      this.authService.loginOpt(auth).pipe(
        map(user => new OptLoginSuccess({ user: user})),
        catchError(error => of(new OptLoginFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  loginSuccessOpt$ = this.actions$.pipe(
    ofType(OptAuthActionTypes.OptLoginSuccess),
    tap(() => this.router.navigate(['/']))
  );

  @Effect({ dispatch: false })
  loginRedirectOpt$ = this.actions$.pipe(
    ofType(OptAuthActionTypes.OptLoginRedirect, OptAuthActionTypes.OptLogout, OptAuthActionTypes.OptClearCredentials),
    tap(authed => {
      this.router.navigate(['/opt/login']);
    })
  );

  @Effect()
  getUser$ = this.actions$.pipe(
    ofType(OptAuthActionTypes.GetUser),
    combineLatest(this.afAuth.authState, this.afAuth.idToken),
    map(([action, authData, idToken]) => {
      if (authData) {
        /// User logged in
        const user = new User(authData.uid, authData.displayName, idToken);
        return new LoginSuccess(user);
      } else {
        /// User not logged in
        return new ClearCredentials();
      }
    },
      catchError(err => of(new LoginFailure(err))))
  );

  @Effect()
  login$ = this.actions$.pipe(
    ofType<Login>(OptAuthActionTypes.Login),
    switchMap(payload => {
      return from(this.facebookLogin());
    }),
    map(credential => {
      // successful login
      return new GetUser();
    })
  );

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType(OptAuthActionTypes.LoginSuccess),
    tap(() => this.router.navigate(['/opt/login']))
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType(OptAuthActionTypes.LoginRedirect, OptAuthActionTypes.Logout, OptAuthActionTypes.ClearCredentials),
    tap(authed => {
      this.router.navigate(['/opt/login']);
    })
  );

  private facebookLogin(): any {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.afAuth.auth.signInWithPopup(provider);
  }

  constructor(
    private actions$: Actions,
    private authService: OptAuthService,
    private router: Router,
    private afAuth: AngularFireAuth
  ) {}
}
