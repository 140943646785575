import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';
import { SystemMessagesService } from './system-messages.service';
import { SystemMessage } from './SystemMessage';
import { State } from './../../reducers/index';
import { Store } from '@ngrx/store';
import { RedirectToSystemMessageScreen } from './../../actions/root.actions';

@Injectable()
export class SystemStatusGuard implements CanActivate {
  constructor(private systemMessage: SystemMessagesService, private store: Store<State>) {}

  canActivate(): Observable<boolean> {
    return this.systemMessage.getSystemMessages().pipe(
      map(m => {
          console.log(m);
        if (m.some((ms:SystemMessage) => ms.active)) {
          this.store.dispatch(new RedirectToSystemMessageScreen());
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
